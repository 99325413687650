// Modules
import React from 'react'
import styled from '@emotion/styled'
import {
  graphql,
  Link,
  StaticQuery,
} from 'gatsby'

// Scripts
import setImage from 'scripts/setImage'
import {
  largeUp,
  mediumUp,
} from 'scripts/media-query'

// Theme
import theme from 'scripts/theme'

const RecentPosts = () => {
  const RecentPostsWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: ${theme.space.betweenRows} ${theme.space.betweenColumns};

    .recent-post {
      width: 100%;

      ${mediumUp} {
        max-width: 448px;
      }
    }

    .recent-post-card {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }

    .image-wrapper {
      height: 45vw;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;

      ${mediumUp} { height: 25vw; }
      ${largeUp} {
        height: 15vw;
        max-height: 200px;
      }
    }

    .post-details {
      flex-grow: 1;
      padding: ${theme.space.all};
      background-color: ${theme.color.grey};
      color: ${theme.color.white};
      text-align: center;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .date {
      width: 80%;
      margin: 0 auto 1rem;
      font-size: .75rem;
      border-bottom: 1px solid;
    }

    .title {
      margin: 0;
      font-family: ${theme.font.cardo};
      font-size: 1.25rem;
    }
  `

  return (
    <StaticQuery
      query={
        graphql`
        query ThankYouPageBlogPosts {
          allMarkdownRemark(
            sort: {order: DESC, fields: frontmatter___date}
            limit: 3
            filter: {frontmatter: {templateKey: {eq: "blog-post"}}}
          ) {
            edges {
              node {
                fields {
                  slug
                }
                frontmatter {
                  date(formatString: "MMMM DD, YYYY")
                  postData {
                    postImageData {
                      image {
                        childImageSharp {
                          fluid {
                            originalImg
                            originalName
                          }
                        }
                      }
                    }
                  }
                  title
                }
                excerpt(pruneLength: 150)
              }
            }
          }
        }
      `
      }
      render={data => {
        const { allMarkdownRemark: { edges }} = data
        return (
          <RecentPostsWrapper>
            {
              edges.map((postData, index) => {
                const {
                  node: {
                    fields: { slug },
                    frontmatter: {
                      date,
                      postData: { postImageData: { image }},
                      title,
                    },
                  },
                } = postData

                const img = setImage(image)

                return (
                  <Link
                    className='recent-post'
                    key={`thank-you-page-post-${index}`}
                    to={slug}
                  >
                    <div className='recent-post-card'>
                      <div
                        className='image-wrapper'
                        style={{ backgroundImage: `url(${img})` }}
                      />
                      <div className='post-details'>
                        <p className='date'>{date}</p>
                        <p className='title'>{title}</p>
                      </div>
                    </div>
                  </Link>
                )
              })
            }
          </RecentPostsWrapper>
        )
      }}
    />
  )
}

export default RecentPosts
