// Modules
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

// Components
import Container from 'components/global-components/Container'
import Hero from 'components/global-components/Hero'
import Layout from 'components/global-components/Layout'
import MetaData from 'components/global-components/MetaData'
import RecentPosts from 'components/RecentPosts'

// PropTypes
const propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        heroData: PropTypes.objectOf(PropTypes.any),
        metadata: PropTypes.objectOf(PropTypes.any),
        recentPostsHeader: PropTypes.string.isRequired,
      }),
    }),
  }),
}

const ThankYouPageTemplate = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: {
        heroData,
        metadata,
        recentPostsHeader,
      },
    },
  } = data

  const newMetaData = {
    ...metadata,
    robots: {
      follow: metadata.follow,
      index: metadata.index,
    },
  }

  return (
    <Layout>
      <MetaData metaData={newMetaData} />
      <Hero heroData={heroData} />
      <section className='bottom-section-space'>
        <Container>
          <h2
            style={{
              marginTop: 0,
              textAlign: 'center',
            }}
          >
            {recentPostsHeader}
          </h2>
          <RecentPosts />
        </Container>
      </section>
    </Layout>
  )
}

ThankYouPageTemplate.propTypes = propTypes
export default ThankYouPageTemplate
export const pageQuery = graphql`
  query ThankYouTemplate {
    markdownRemark(frontmatter: {templateKey: {eq: "thanks"}}) {
      frontmatter {
        metadata {
          description
          follow
          index
          title
          ogImage {
            childImageSharp {
              fluid {
                originalImg
                originalName
              }
            }
          }
        }
        heroData {
          isReversed
          header
          curveNumber
          content
          backgroundColor {
            selectedColorOption
            customColor
          }
          fontColor {
            selectedColorOption
            customColor
          }
          imageData {
            imageAlt
            image {
              childImageSharp {
                fluid {
                  originalName
                  originalImg
                }
              }
            }
          }
        }
        recentPostsHeader
      }
    }
  }
`
